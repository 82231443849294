import React, { useEffect, useState } from 'react'
import { Link, useStaticQuery } from 'gatsby'
import SEO from '../components/seo'
import ReviewSlider from '../components/ReviewSlider'
import { Section, Grid, Image, Button, Gutter, Hero, Panel } from '@patomation/react-ui-components'
import FooterBar from '../components/FooterBar'
import find from '@patomation/find'
import isMobile from 'ismobilejs'
import TalkTo from '../components/TalkTo'

const Home = () => {
  const data = useStaticQuery(graphql`
    query HomeNavigation {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/menu/"}}) {
        edges {
          node {
            frontmatter {
              menu {
                title
                link
              }
            }
          }
        }
      }
    }
  `)

  const [ height, setHeight ] = useState()
  useEffect(() => {
    if( !isMobile().any ) {
      setHeight(window.innerHeight)
    }
  })

  return (
  <div style={{position:'relative'}}>
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossOrigin="anonymous"/>
    <SEO title={'Private Guide in Chiang Mai'} description={'Make your trip to Thailand as memorable as it is enjoyable'}/>
    <Hero
      image='https://res.cloudinary.com/northernthaiescape/image/upload/e_improve,w_1000/v1572353446/20190921_110427_dtt2u0.jpg'
      overlayColor='#324851'
      background='#324851'
      height={height}>


      <h1>Private Guide in Chiang Mai</h1>
      <Gutter/>

      <p>Make your trip to Thailand as memorable as it is enjoyable</p>
      <Gutter/>

      <Link to='/packages'>
      <Button
        kind='outline'
        color='#ffffff'
        title='Choose Your Next Adventure'/>
      </Link>

    </Hero>

    <menu style={{
      position: 'absolute',
      top: '1rem',
      right: '1rem'
    }}>
      {find('menu', data).map(item => {
        return item.link !== '/' //Omit Home Link for Home page
          ? <span
              key={`nav_item_${item.title}`}
              style={{
                marginLeft: '1rem'
              }}>
                <Link
                  to={item.link}
                  style={{
                    color: '#ffffff',
                    fontWeight: 'bold'
                  }}>
                    {item.title}</Link>
            </span>
          : null
      })}
    </menu>
    <TalkTo />
    <Section
      background='#324851'
      color='#ffffff'
      style={{
        margin: 0,
        textAlign: 'center'
      }}
      height={height}>

      <h1>Who we are</h1>

      Private Guide in Chiang Mai aims to provide exclusive authentic experiences to travelers from around the world.
      We provide professional English speaking Tour Guides as well as Drivers to take you where your heart desires.
      Explore a side of Thailand that is truly beautiful and full of adventure.

      <Gutter/>

      <Grid col={3} gap='1rem' className='value-props__grid'>
        <Panel
          style={{
            border: '1px solid #ffffff',
            background: 'none',
            margin: '0'
          }}>
          <h1>Over 15 years experience</h1>
          <Gutter/>
          We are a team of priofessional guides and drivers that have years of experience providing tour services all over Thailand.
        </Panel>
        <Panel
          style={{
            border: '1px solid #ffffff',
            background: 'none',
            margin: '0'
          }}>
          <h1>Custom Private Tours</h1>
          <Gutter/>
          We can make customized tour for any occasion.
          Wether it be Elephant Sancuary visists, ATV riding, rafting, nature hikes, visits to the hill tribe villages, night tours, street food tours, temple visits, Chiang Rai golden triangle and many more.
          Let us create a custom package that is tailored to your needs.
        </Panel>
        <Panel
          style={{
            border: '1px solid #ffffff',
            background: 'none',
            margin: '0'
          }}>
          <h1>All over Thailand</h1>
          <Gutter/>
          Where ever you want to go, we can accomidate you.
          We offer Tours in Chiang Mai, Chiang Rai, Pai, Bangkok, Pattaya, Krabi, Phuket, Koh Tao, Koh Samui, Koh Pi Pi, and Other Islands.
        </Panel>


      </Grid>
    </Section>


    <Section
      background='#86AC41'
      color='#324851'
      style={{
        margin: 0,
        textAlign: 'center'
      }}
      height={height}>
      <Grid col={2} gap='1rem' className='cta__grid'>

        <div style={{textAlign: 'left'}}>
          <h1>Tour Packages</h1>
          Take a look a the packages that we offer. Mix and match tours, choose the days and travelers.

          <Gutter/>
          <Link to='/packages'>
            <Button
              kind='outline'
              color='#324851'
              title='Packages'/>
          </Link>
        </div>


        <div style={{textAlign: 'left'}}>
          <h1>Custom Tours</h1>
          We are more than happy to create a unique exclusive tour package to fit your needs.
          We can accomidate large groups and take you anywhere your heart desires.

          <Gutter/>
          <Link to='/contact'>
            <Button
              kind='outline'
              color='#324851'
              title='Contact Us'/>
          </Link>
        </div>


      </Grid>

    </Section>

    <Section
      background='#324851'
      color='#ffffff'
      height={height}>

      <h1 style={{textAlign:'center'}}>Our Happy Customers</h1>
      <Gutter/>
      <ReviewSlider/>

      <Gutter/>
      <div style={{
        textAlign: 'center'
      }}>
        <Link to='/review'>
        <Button
          kind='outline'
          color='#ffffff'
          title='Leave a Review'/>
        </Link>
      </div>
    </Section>

    <Grid col={3}>
      <Image square src='https://res.cloudinary.com/northernthaiescape/image/upload/e_improve,w_1000/v1569755409/lanna_vkldyt.jpg' />
      <Image square src='https://res.cloudinary.com/northernthaiescape/image/upload/e_improve,w_500/v1570544302/300874_ufmwrb.jpg' />
      <Image square src='https://res.cloudinary.com/northernthaiescape/image/upload/e_improve,w_500/v1569415546/20181112_104539_rt3wlu.jpg' />
      <Image square src='https://res.cloudinary.com/northernthaiescape/image/upload/e_improve,w_500/v1572075252/20190604_110122_x9qfrp.jpg' />
      <Image square src='https://res.cloudinary.com/northernthaiescape/image/upload/e_improve,w_500/v1572075431/20191010_184207_fupzrd.jpg' />
      <Image square src='https://res.cloudinary.com/northernthaiescape/image/upload/e_improve,w_500/v1569415542/20181117_075415_v3gvzb.jpg' />
    </Grid>


  <FooterBar/>
  </div>
)}

export default Home
