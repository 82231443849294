import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import { Gutter, Row } from '@patomation/react-ui-components'
import Stars from './Stars.js'

const Quote = ({
  id, stars, name, date, text, link, excerpt
}) =>

<Row id={id} className='quote'>

  <h1 style={{
    color:'#86AC41',
    fontSize: '4rem',
    display: 'inline-block',
    paddingRight: '1rem',
    alignSelf: 'flex-start'
  }}>&#8220;</h1>



  <div
    className='quote__content'
    style={{
      display: 'inline'
    }}>

    <div style={{textAlign:'center'}}>
      <Stars total={stars} />
    </div>
    <Gutter/>

    <div style={{
      fontSize: '1.5rem'
    }}>
      { excerpt && text.length > excerpt
        ? `${text.substring(0,excerpt)}... `
        : text }
      { excerpt && text.length > excerpt && link
        ? <Link to={link} style={{color:'#86AC41'}}>Read Full Review</Link>
        : null }
    </div>

    <div style={{textAlign: 'right'}}>
      {name} <Gutter vertical/> {date}
    </div>
  </div>

  <h1 style={{
    color:'#86AC41',
    fontSize: '4rem',
    display: 'inline',
    paddingLeft: '1rem',
    alignSelf: 'flex-end'
  }}>&#8221;</h1>


</Row>

Quote.propTypes = {
  id: PropTypes.string,
  stars: PropTypes.number,
  name: PropTypes.string,
  date: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  excerpt: PropTypes.number
}

export default Quote
