import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@patomation/react-ui-components'

const Stars = ({
  total, //how many stars should be active
  max, //Max number of stars default is 5
}) =>

<div>
  {Array(max || 5).fill(0).map( ( _, index ) =>
    <Icon
      key={`star_${index}`}
      name='star'
      color={index+1 <= total ? '#86AC41' : null} />
  )}
</div>

Stars.propTypes = {
  total: PropTypes.number,
  max: PropTypes.number
}

export default Stars
