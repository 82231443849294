import React from 'react'
// import { Link } from "gatsby"
import { Carousel } from '@patomation/react-ui-components'
import Quote from './Quote.js'
import find from '@patomation/find'
import { useStaticQuery, graphql } from 'gatsby'

const ReviewSlider = () => {

  const data = useStaticQuery(graphql`
    query SliderReviews {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/reviews/"}}) {
        edges {
          node {
            frontmatter {
              reviews {
                name
                date
                stars
                text
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Carousel
      arrows={false}
      autoplaySpeed={6500}
      autoplay={true}
      >
      {find('reviews', data).map( (review, index) =>
        <Quote
          key={review.name}
          {...review}
          excerpt={250}
          link={`/reviews/#review_${index}`} />
      )}
    </Carousel>
  )
}

export default ReviewSlider
